<template>
  <div class="home-page">
    <div class="bannar">
      <!-- <img src="../../image/bg.png"> -->
      <img :src="bannerSrc" v-if="bannerType && windowWidth > 900" />
      <video
        v-else-if="!bannerType && windowWidth > 900"
        muted="muted"
        id="startvideo"
        class="video"
        :src="bannerSrc"
        type="video/mp4"
        autoplay="autoplay"
        controls="controls"
        loop="-1"
      >
        <p>你的浏览器不支持video标签.</p>
      </video>
      <img :src="mobileBannerSrc" v-else />
      <!-- <video-player v-else class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="true"
                @ready="playerReadied"
                :options="playerOptions">t
            </video-player> -->
      <!-- <div v-else class="video-player">
                <video-player :videoUrl="bannerSrc"></video-player>
            </div> -->
      <div class="overlay"></div>
      <div class="bannar-info">
        <div class="bannar-title">天府国际赛道</div>
        <div class="bannar-detail">{{ bannerTitle }}</div>
      </div>
    </div>
    <main>
      <div class="main-activity">
        <div class="main-mode-top" style="padding: 0">
          <div class="top-left">
            <img src="../../../image/home/title-logo.png" />
            <h2>{{ $t("home.activity") }}</h2>
          </div>
          <div class="top-right" @click="goToClick(1)">
            <span>{{ $t("home.more") }}</span>
            <img src="../../../image/home/right2.png" />
          </div>
        </div>
        <div
          class="main-activity-item"
          v-for="(item, index) in activityList"
          :key="index"
          @click="activityDetails(item.id)"
        >
          <img :src="item.src" :alt="item.name" />
          <div class="main-activity-item-detail">
            <span>{{ $t("home.more") }}</span>
            <img src="../../../image/home/right2.png" />
          </div>
        </div>
      </div>
      <div class="news-bg">
        <div class="main-news">
          <!-- <div class="news-top">
                        <h2>{{$t('home.news')}}</h2>
                        <div class="detail" @click="goToClick(2)">
                            <span>{{$t('home.more')}}</span>
                            <div class="details-bg"></div>
                        </div>
                    </div> -->
          <div class="main-mode-top">
            <div class="top-left">
              <img src="../../../image/home/title-logo.png" />
              <h2>{{ $t("home.news") }}</h2>
            </div>
            <div class="top-right" @click="goToClick(2)">
              <span>{{ $t("home.more") }}</span>
              <img src="../../../image/home/right2.png" />
            </div>
          </div>
          <div class="news-content">
            <div
              @click="newsDetails(item.id)"
              v-for="(item, index) in newsList"
              :key="index"
              class="news-content-item"
            >
              <!-- <img src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/128071878cc044d5a9ff30a1a91eb8f2_mergeImage.png"> -->
              <div class="item-img">
                <img :src="item.src" :title="item.title" />
              </div>
              <div class="item-info">
                <h3>{{ item.title }}</h3>
                <div class="info-content">
                  {{ item.detail || "暂无介绍" }}
                </div>
                <div class="detail" @click.stop="newsDetails(item.id)">
                  <span>{{ $t("home.details") }}</span>
                  <div class="detail-bg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-project">
        <div class="main-mode-top">
          <div class="top-left">
            <img src="../../../image/home/title-logo.png" />
            <h2>{{ $t("home.project") }}</h2>
          </div>
          <div class="top-right" @click="goToClick(3)">
            <span>{{ $t("home.more") }}</span>
            <img src="../../../image/home/right2.png" />
          </div>
        </div>
        <div class="news-content">
          <div
            @click="projectClick(item.id)"
            v-for="(item, index) in projectList"
            :key="index"
            class="news-content-item"
          >
            <!-- <img src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/128071878cc044d5a9ff30a1a91eb8f2_mergeImage.png"> -->
            <div class="item-img">
              <img :src="item.imgSrc" :title="item.name" />
            </div>
            <div class="item-info">
              <h3>{{ item.name }}</h3>
              <div class="info-content">
                {{ item.detail || "暂无介绍" }}
              </div>
              <div class="detail" @click.stop="projectClick(item.id)">
                <span>{{ $t("home.details") }}</span>
                <div class="detail-bg"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-business-box">
        <div class="main-mode-top">
          <div class="top-left">
            <img src="../../../image/home/title-logo.png" />
            <h2>{{ $t("home.business") }}</h2>
          </div>
          <div class="top-right" @click="goToClick(4)">
            <span>{{ $t("home.more") }}</span>
            <img src="../../../image/home/right2.png" />
          </div>
        </div>
        <div class="main-business">
          <div class="main-business-swiper">
            <div
              v-for="(item, index) in businessList"
              :key="index"
              class="business-content"
              @click="businessClick(item.id)"
            >
              <img
                v-if="item.imgSrc"
                :src="item.imgSrc"
                :alt="item.name"
                class="business-img"
              />
              <img
                v-else
                src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/695c1c998419414586ac702b35400ee5_mergeImage.png"
                class="business-img"
              />
              <div class="business-content-title">
                <div>{{ item.name }}</div>
                <div>
                  <span>{{ $t("home.more") }}</span>
                  <img src="../../../image/home/right3.png" />
                </div>
              </div>
            </div>
          </div>
          <div class="business-left" @click="businessLeftClick"></div>
          <div class="business-right" @click="businessRightClick"></div>
        </div>
      </div>
      <div class="main-brand">
        <!-- <h2>{{$t('home.brand')}}</h2> -->
        <div class="main-mode-top" style="padding: 0px">
          <div class="top-left">
            <img src="../../../image/home/title-logo.png" />
            <h2>{{ $t("home.brand") }}</h2>
          </div>
        </div>
        <div class="main-brand-content">
          <div
            v-for="(item, index) in brandList"
            :key="index"
            class="brand-content-item"
          >
            <div class="brand-content-item-bg">
              <img :src="item.src" alt="" :title="item.name" />
            </div>
            <div class="brand-content-item-title">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="our-bg">
        <div class="main-our">
          <!-- <h2>{{$t('home.ours')}}</h2> -->
          <div class="main-mode-top">
            <div class="top-left">
              <img src="../../../image/home/title-logo.png" />
              <h2>{{ $t("home.ours") }}</h2>
            </div>
          </div>
          <div class="our-content">
            <!-- {{ourInfo.content}} -->
            <div v-html="ourInfo.content" class="our-content-info"></div>
          </div>
          <div class="our-detail">
            <div class="detial" @click="ourClick">{{ $t("home.more") }}</div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  getBanner,
  getActivity,
  getBrand,
  getOur,
  getNew,
  getProject,
  getBusiness,
  getBusinessInfo,
} from "../../../api/homeApi";
import videoPlayer from "../component/video-player.vue";
export default {
  components: {
    videoPlayer,
  },
  data() {
    return {
      searchValue: "",
      type: 0,
      menuList: [
        "关于我们",
        "赛道探索",
        "赛事活动",
        "新闻资讯",
        "娱乐体验",
        "联系我们",
      ],
      menuOption: 0,
      projectOption: 0,
      lunboIdx: 0,
      scrollTop: 0,
      bannerSrc: "",
      mobileBannerSrc: "",
      bannerType: 1, //1.图片 0.视频
      bannerTitle: "",
      player: null,
      activityList: [],
      newsList: [],
      projectList: [],
      brandList: [],
      ourInfo: {},
      businessList: [],
      windowWidth: 0,
      businessEndIdx: 1,
      carouselStatus: true, //true.先左 false.向右
      timer: null,
      brandIdx: 0,
    };
  },
  computed: {},
  created() {
    this.getBanner();
    this.getActivity();
    this.getBrand();
    this.getNew();
    this.getOur();
    this.getProject();
    this.getBusiness();
    this.cachesTimer();
    // this.getBusinessInfo()

    // this.getWindowWidth()
    // window.addEventListener("resize", this.getWindowWidth);
  },
  destroyed() {
    // window.removeEventListener("resize", this.getWindowWidth)
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    // 轮播图播放
    cachesTimer() {
      this.timer = setInterval(() => {
        // this.rightClick()
        this.businessRightClick();
        this.brandLunbo();
      }, 5000);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      );
      return flag;
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
      // if(this.windowWidth > 900) {
      //     if(this.timer) {
      //         clearInterval(this.timer)
      //     }
      //     this.cachesTimer()
      // }else {
      //     if(this.timer) {
      //         clearInterval(this.timer)
      //     }
      // }
    },
    handleCommand(command) {
      this.type = command;
    },
    menuClick(val) {
      this.menuOption = val;
    },
    businessLeftClick() {
      var pic = document.querySelector(".main-business-swiper");
      if (this.businessEndIdx == 0) {
        this.businessEndIdx = pic.children.length - 1;
        var num = this.businessEndIdx * 100;
        pic.style.left = `-${num}%`;
      }
      this.businessEndIdx--;
      let index = this.businessEndIdx;
      this.animation({
        ele: pic,
        target: -pic.children[0].offsetWidth * index,
        attr: "left",
      });
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.cachesTimer();
    },
    businessRightClick() {
      var pic = document.querySelector(".main-business-swiper");
      if (this.businessEndIdx == pic.children.length - 2) {
        this.businessEndIdx = 0;
        pic.style.left = 0;
      }
      this.businessEndIdx++;
      let index = this.businessEndIdx;
      this.animation({
        ele: pic,
        target: -pic.children[0].offsetWidth * index,
        attr: "left",
      });
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.cachesTimer();
    },
    animation(options) {
      // console.log(options);
      //先清除再开启
      let that = this;
      clearInterval(options.ele.timer);
      //使用定时器
      options.ele.timer = setInterval(function () {
        //获取到现在的位置
        var begin = parseInt(that.getStyle(options.ele, options.attr));
        //步长  step = (target - begin)/10
        var step = (options.target - begin) / 10;
        step = step > 0 ? Math.ceil(step) : Math.floor(step);
        //位置+步长
        var res = begin + step;
        //赋值
        options.ele.style[options.attr] = res + "px";

        if (res == options.target) {
          clearInterval(options.ele.timer);
          //当有这个函数名称的时候，再调用函数
          //两个条件必须都满足，才能执行
          options.callback && options.callback();
        }
      }, 30);
    },
    getStyle(obj, attr) {
      if (window.getComputedStyle) {
        return getComputedStyle(obj, null)[attr];
      } else {
        return obj.currentStyle[attr];
      }
    },
    backClick() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    // 获取banner图
    getBanner() {
      getBanner({
        plate: 0,
      }).then((res) => {
        if (res.code == 0) {
          let arr = JSON.parse(res.infos[0].image);
          if (
            [
              "image/png", // .png
              "image/jpeg", // .jpg .jpeg
              "image/x-ms-bmp", // .bmp
              "image/gif", // .gif
              "image/tiff", // .tif .tiff
            ].includes(arr[0].type)
          ) {
            this.bannerType = 1;
          } else {
            this.bannerType = 0;
          }
          this.bannerSrc = this.formatImageUrl(
            JSON.parse(res.infos[0].image)[0].raw
          );
          this.bannerTitle = res.infos[0].content;
          let mobileArr = JSON.parse(res.infos[0].mobileImage);
          if (mobileArr.length > 0) {
            this.mobileBannerSrc = this.formatImageUrl(mobileArr[0].raw);
          }
        }
      });
    },
    // 获取活动
    getActivity() {
      getActivity({
        limit: 4,
        page: 1,
      }).then((res) => {
        if (res.code == 0) {
          this.activityList = res.page.list.map((item) => {
            let str = item.details
              .replace(/<[^<>]+>/g, "")
              .replace(/&nbsp;/gi, "");
            item.detail = str;
            let arr = item.endTime.split("-");
            item.month = arr[1];
            item.day = arr[2];
            item.src = "";
            let arr2 = JSON.parse(item.image);
            if (arr2.length > 0) {
              item.src = this.formatImageUrl(arr2[0].raw);
            }
            return item;
          });
        }
      });
    },
    // 获取咨询
    getNew() {
      getNew({
        limit: 3,
        page: 1,
      }).then((res) => {
        if (res.code == 0) {
          this.newsList = res.page.list.map((item) => {
            let str = item.content
              .replace(/<[^<>]+>/g, "")
              .replace(/&nbsp;/gi, "");
            item.detail = str;
            item.src = this.formatImageUrl(JSON.parse(item.image)[0].raw);
            return item;
          });
        }
      });
    },
    // 获取项目
    getProject() {
      getProject({
        limit: 3,
        page: 1,
      }).then((res) => {
        if (res.code == 0) {
          this.projectList = res.page.list;
          res.page.list.forEach((item, idx) => {
            item.imgSrc = this.formatImageUrl(JSON.parse(item.image)[0].raw);
          });
        }
      });
    },
    // 获取合作品牌
    getBrand() {
      getBrand({
        limit: -1,
        page: 1,
      }).then((res) => {
        if (res.code == 0) {
          this.brandList = [];
          let arr = res.page.list.map((item) => {
            item.src = this.formatImageUrl(JSON.parse(item.image)[0].raw);
            return item;
          });
          this.brandList = arr;
          if (arr.length > 3) {
            let start = JSON.parse(JSON.stringify(arr)).splice(0, 2);
            let end = arr[arr.length - 1];
            let arr2 = arr;
            arr2.unshift(end);
            start.forEach((o) => {
              arr2.push(o);
            });
            this.brandList = arr2;
          } else {
            this.brandList = arr;
          }
        } else {
          this.brandList = [];
        }
      });
    },
    // 合作品牌轮播
    brandLunbo() {
      var pic = document.querySelector(".main-brand-content");
      if (this.brandIdx == pic.children.length - 3) {
        this.brandIdx = 0;
        pic.style.left = 0;
      }
      this.brandIdx++;
      var index = this.brandIdx;
      this.animation({
        ele: pic,
        target: -pic.children[0].offsetWidth * index,
        attr: "left",
      });
    },
    // 关于我们
    getOur() {
      getOur().then((res) => {
        if (res.code == 0) {
          this.ourInfo = res.info;
        }
      });
    },
    // 获取业务
    getBusiness() {
      getBusiness().then((res) => {
        // console.log(res)
        if (res.code == 0) {
          this.businessList = res.infos;
          res.infos.forEach((item) => {
            item.imgSrc = this.formatImageUrl(JSON.parse(item.image)[0].raw);
          });
          let arr = this.businessList;
          let len = res.infos.length;
          if (len > 1) {
            let start = JSON.parse(JSON.stringify(arr)).splice(0, 1);
            let end = arr[arr.length - 1];
            let arr2 = arr;
            arr2.unshift(end);
            start.forEach((o) => {
              arr2.push(o);
            });
            this.businessList = arr2;
          }
        }
      });
    },

    goToClick(val) {
      if (val == 1) {
        this.$router.push("/activity");
      } else if (val == 2) {
        this.$router.push("/news");
      } else if (val == 3) {
        this.$router.push("/home/projectList");
      } else if (val == 4) {
        this.$router.push("/home/businessList");
      }
    },
    // 新闻详情
    newsDetails(id) {
      this.$router.push(`/news-details/${id}`);
    },
    // 活动详情
    activityDetails(id) {
      this.$router.push(`/activity-details/${id}`);
    },
    // 业务详情
    businessClick(id) {
      this.$router.push(`/home/business/${id}`);
    },
    projectClick(id) {
      this.$router.push(`/home/project/${id}`);
    },
    ourClick() {
      this.$router.push(`/home/our`);
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and(max-width: 900px) {
  .home-page {
    width: 100%;
    .bannar {
      width: 100%;
      // height: 5.5rem;
      background: #666;
      // margin-top: 20px;
      position: relative;
      .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 9;
      }
      img {
        // height: 100%;
        width: 100%;
        vertical-align: middle;
        height: 30vw;
      }
      .bannar-info {
        position: absolute;
        top: 20%;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 9;
        padding: 0 20px;
        .bannar-title {
          font-size: 36px;
          color: #fff;
          margin-bottom: 20px;
        }
        .bannar-detail {
          font-size: 24px;
          color: #fff;
          // cursor: pointer;
        }
      }
      .video-player {
        width: 100%;
        height: 100%;
      }
      .video {
        object-fit: fill;
        width: 100%;
        height: 30vw;
      }
    }
    main {
      width: 100%;
      .main-mode-top {
        display: flex;
        justify-content: space-between;
        height: 50px;
        width: 100%;
        margin-bottom: 50px;
        padding: 0 20px;
        .top-left {
          display: flex;
          align-items: center;
          img {
            width: 55px;
            height: 15px;
            margin-right: 10px;
          }
          h2 {
            margin: 0;
          }
        }
        .top-right {
          display: flex;
          align-items: center;
          font-size: 24px;
          cursor: pointer;
          img {
            width: 8px;
            height: 14px;
            margin-left: 10px;
          }
        }
      }
      .our-bg {
        width: 100%;
        background-color: #f1f1f1;
      }
      .main-our {
        box-sizing: border-box;
        padding: 0.6rem 20px;
        // width: 1200px;
        margin: 0 auto;
        // background: #F1F1F1;
        h2 {
          text-align: center;
          margin: 0;
          margin-bottom: 0.3rem;
        }
        /deep/.our-content {
          margin-bottom: 0.3rem;
          font-size: 24px;
          color: #666;
          line-height: 2;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          p {
            span {
              color: #666 !important;
              display: inline-block;
              text-indent: 2em;
            }
          }
        }
        .our-detail {
          width: 100%;
          display: flex;
          justify-content: center;
          .detial {
            border: 1px solid #4c4c4c;
            padding: 0.05rem 0.2rem;
            cursor: pointer;
          }
          .detial:hover {
            background: #4c4c4c;
            color: #fff;
          }
        }
      }
      .main-business-box {
        position: relative;
      }
      .main-business {
        // width: 550px;
        width: 100%;
        // background-color: #333;
        display: flex;
        overflow: hidden;
        // position: relative;
        width: 710px;
        margin: 0 auto;
        .main-business-swiper {
          position: relative;
          display: flex;
          left: -100%;
        }
        .business-left {
          background: url("../../../image/home/left.png") center center
            no-repeat;
          height: 40px;
          width: 40px;
          background-size: 100%;
          position: absolute;
          left: 40px;
          top: 290px;
          cursor: pointer;
        }
        .business-right {
          background: url("../../../image/home/right.png") center center
            no-repeat;
          height: 40px;
          width: 40px;
          background-size: 100%;
          position: absolute;
          right: 40px;
          top: 290px;
          cursor: pointer;
        }
        // flex-wrap: wrap;
        .business-content {
          display: flex;
          width: 100%;
          flex-shrink: 0;
          position: relative;
          // padding-right: 10px;
          height: 420px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: 0.5s all;
          }
          .business-content-title {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 100%;
            font-size: 20px;
            color: #fff;
            text-align: right;
            padding: 5px 10px;
            box-sizing: border-box;
            background-color: rgba(255, 103, 32, 0.5);
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            div:first-child {
              flex: 1;
              margin-right: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            }
            img {
              height: 14px;
              width: 8px;
              margin: 0 10px;
            }
          }
        }
      }
      .main-activity {
        box-sizing: border-box;
        padding: 1rem 20px;
        // width: 1200px;
        display: flex;
        margin: 0 auto;
        flex-wrap: wrap;
        // height: 6.9rem;
        .main-activity-item:nth-child(2n + 1) {
          margin-right: 0;
        }
        .main-activity-item {
          width: calc((100% - 20px) / 2);
          height: 242px;
          background-color: #fff;
          margin-bottom: 20px;
          margin-right: 20px;
          position: relative;
          overflow: hidden;
          box-shadow: 1px 1px 10px #ccc;
          img {
            height: 100%;
            width: 100%;
            vertical-align: middle;
            transition: 0.5s all;
          }
          .main-activity-item-detail {
            font-size: 20px;
            position: absolute;
            bottom: 10px;
            right: 10px;
            cursor: pointer;
            img {
              width: 8px;
              height: 14px;
              margin-left: 10px;
            }
          }
        }
      }
      .news-bg {
        width: 100%;
        background-color: #ebebeb;
      }
      .main-news,
      .main-project {
        box-sizing: border-box;
        padding: 0.8rem 0rem 1rem 0rem;
        // height: 7.05rem;
        margin: 0 auto;
        // width: 1200px;
        .news-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.6rem;
          padding: 0 20px;
          h2 {
            margin: 0;
          }
          .detail {
            padding: 0.05rem 0rem;
            width: 1.4rem;
            border: 1px solid #333;
            // width: max-content;
            font-size: 20px;
            color: #4c4c4c;
            cursor: pointer;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            .details-bg {
              background: url("../../../image/right.png") center center
                no-repeat;
              background-size: 100% 100%;
              height: 25px;
              width: 30px;
            }
            // img {
            //     height: 25px;
            //     // margin-left: 20px;
            // }
          }
          .detail:hover {
          }
        }
        .news-content {
          display: flex;
          flex-wrap: wrap;
          padding: 0 20px;
          .news-content-item {
            width: 100%;
            margin-bottom: 20px;
            img {
              width: 100%;
              height: 2.4rem;
              background-color: #c1c1c1;
              display: block;
            }
            .item-info {
              background-color: #fff;
              box-sizing: border-box;
              padding: 0.2rem;
              h3 {
                margin: 0;
                padding-bottom: 0.1rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .info-content {
                font-size: 20px;
                line-height: 2;
                margin-bottom: 0.2rem;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #666;
                min-height: 80px;
              }
              .detail {
                padding: 0.05rem 0.2rem;
                // border: 1px solid #4C4C4C;
                width: 100%;
                font-size: 0.16rem;
                color: #ff6720;
                transition: 0.5s all;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .detail-bg {
                  background: url("../../../image/home/right2.png") center
                    center no-repeat;
                  background-size: 100% 100%;
                  height: 14px;
                  width: 8px;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
      .main-project {
        box-sizing: border-box;
        padding: 0.8rem 0rem 1rem 0rem;
        margin: 0 auto;
        // background-color: #EBEBEB;
        h2 {
          text-align: center;
          margin: 0;
          margin-bottom: 0.8rem;
        }
        .news-content {
          .news-content-item {
            background-color: #c1c1c1;
          }
        }
      }
      .main-brand {
        box-sizing: border-box;
        padding: 0.8rem 0rem 1rem 0rem;
        width: 710px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        h2 {
          width: 100%;
          text-align: center;
          margin: 0;
          margin-bottom: 0.8rem;
        }
        .main-brand-content {
          display: flex;
          position: relative;
          // margin-bottom: 1rem;
          .brand-content-item {
            width: calc(100% / 3);
            flex-shrink: 0;
            padding: 0 20px;
            // height: 2.08rem;
            // border: 1px solid #333;
            .brand-content-item-bg {
              overflow: hidden;
              height: 25vw;
              width: 100%;
              border: 1px solid #333;
              img {
                height: 100%;
                width: 100%;
                vertical-align: middle;
                transition: 0.5s all;
              }
            }
            .brand-content-item-title {
              // height: 25%;
              padding: 20px 0;
              width: 100%;
              font-size: 22px;
              color: #333;
              text-align: center;
              font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    .back-top {
      position: fixed;
      right: 5%;
      bottom: 10%;
      cursor: pointer;
    }
  }
}
@media screen and(min-width: 900px) {
  .home-page {
    width: 100%;
    .bannar {
      width: 100%;
      // height: 5.5rem;
      background: #666;
      position: relative;
      .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 9;
      }
      img {
        // height: 100%;
        width: 100%;
        vertical-align: middle;
        height: 550px;
      }
      .bannar-info {
        position: absolute;
        top: 40%;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 9;
        .bannar-title {
          font-size: 36px;
          color: #fff;
          margin-bottom: 20px;
        }
        .bannar-detail {
          font-size: 24px;
          color: #fff;
          // cursor: pointer;
        }
      }
      .video-player {
        width: 100%;
        height: 100%;
      }
      .video {
        object-fit: fill;
        width: 100%;
        height: 550px;
      }
    }
    main {
      width: 100%;
      .our-bg {
        width: 100%;
        background-color: #f1f1f1;
      }
      .main-our {
        box-sizing: border-box;
        padding: 0.6rem 0rem;
        width: 1200px;
        margin: 0 auto;
        // background: #F1F1F1;
        h2 {
          text-align: center;
          margin: 0;
          margin-bottom: 0.3rem;
        }
        /deep/.our-content {
          margin-bottom: 0.3rem;
          font-size: 0.12rem;
          color: #666;
          line-height: 2;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          p {
            span {
              color: #666 !important;
              display: inline-block;
              text-indent: 2em;
            }
          }
        }
        .our-detail {
          width: 100%;
          display: flex;
          justify-content: center;
          .detial {
            border: 1px solid #4c4c4c;
            padding: 0.05rem 0.2rem;
            // transition: .4s all;
            cursor: pointer;
            transition: border-color 0.3s ease, color 0.2s ease,
              background-color 0.3s ease;
          }
          .detial:hover {
            background: #4c4c4c;
            color: #fff;
            transition: 0.4s all;
          }
        }
      }
      .main-project {
        box-sizing: border-box;
        padding: 0.8rem 0rem 1rem 0rem;
        width: 1200px;
        margin: 0 auto;
        h2 {
          text-align: center;
          margin: 0;
          margin-bottom: 0.8rem;
        }
        .project-content {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          padding: 0.3rem 0;
          padding-top: 0.3rem;
          .project-content-item:nth-child(3n) {
            margin-right: 0px;
          }
          .project-content-item {
            width: calc(100% / 3 - 160px / 3);
            margin-right: 0.8rem;
            text-align: center;
            padding: 0.2rem;
            position: relative;
            transition: 0.5s all;
            padding-top: 40px;
            .item-icon {
              transition: 0.5s all;
              position: absolute;
              top: -0.3rem;
              width: 0.6rem;
              height: 0.6rem;
              left: calc(50% - 30px);
              background-color: #f7f7f7;
              // display: flex;
              // align-items: center;
              // justify-content: center;
              // img {
              //     width: 80%;
              // }
              .item-icon-bg {
                transition: 0.5s all;
                height: 100%;
                width: 100%;
                background: url("../../../image/project_bg1.png") center center
                  no-repeat;
                background-size: 80%;
              }
            }
            .item-content {
              font-size: 0.12rem;
              line-height: 2;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              overflow: hidden;
              color: #666;
              // min-height: 80px;
            }
          }
          .active {
            background-color: #f7f7f7;
            .item-icon {
              background-color: #ee6a22;
            }
          }
          .project-content-item:hover {
            transition: 0.5s all;
            background-color: #f7f7f7;
            .item-icon {
              transition: 0.5s all;
              background-color: #ee6a22;
              .item-icon-bg {
                transition: 0.5s all;
                height: 100%;
                width: 100%;
                background: url("../../../image/project_bg2.png") center center
                  no-repeat;
                background-size: 80%;
              }
            }
          }
        }
      }
      .main-business-box {
        position: relative;
        overflow: hidden;
        // transition: 1s all;
        .business-left {
          background: url("../../../image/home/left.png") center center
            no-repeat;
          height: 40px;
          width: 40px;
          background-size: 100%;
          position: absolute;
          left: 10px;
          top: calc(11vw - 12px);
          cursor: pointer;
        }
        .business-right {
          background: url("../../../image/home/right.png") center center
            no-repeat;
          height: 40px;
          width: 40px;
          background-size: 100%;
          position: absolute;
          right: 10px;
          top: calc(11vw - 12px);
          cursor: pointer;
        }
      }
      .main-business {
        width: 100%;
        background-color: #333;
        display: flex;
        height: 22vw;
        position: relative;
        left: -50%;
        // transition: 1s all;
        .business-word {
          padding: 40px 80px;
        }
        .business-bg {
          background-color: #ee6a22 !important;
        }
        .business-content {
          display: flex;
          width: 50%;
          flex-shrink: 0;
        }
        .business-item {
          width: 50%;
          color: #fff;
          box-sizing: border-box;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          overflow: hidden;
          background-color: #333;
          .item-title {
            // padding-top: .2rem;
            font-size: 0.18rem;
            font-weight: bold;
          }
          .item-content {
            padding-top: 3%;
            line-height: 2;
            font-size: 0.12rem;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            // margin-bottom: 20px;
          }
          .item-detail {
            padding: 0.05rem 0.2rem;
            width: max-content;
            font-size: 0.14rem;
            cursor: pointer;
            position: relative;
            height: 50px;
            line-height: 40px;
            border-left: 2px solid #fff;
            border-right: 2px solid #fff;
            border-top: 2px solid transparent;
            border-bottom: 2px solid transparent;
            transition: 0.5s linear;
          }
          .item-detail:hover {
            // background-color: #ee6a22;
            // border-left: 2px solid #ee6a22;
            // border-right: 2px solid #ee6a22;
            border-top: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transition: 0.5s linear;
          }
          img {
            width: 100%;
            height: 100%;
            transition: 0.5s all;
          }
          img:hover {
            transform: scale(1.1);
            transition: 0.5s all;
          }
        }
      }
      .main-activity {
        box-sizing: border-box;
        padding: 1rem 0rem;
        width: 1200px;
        display: flex;
        margin: 0 auto;
        // height: 6.9rem;
        .activity-left {
          width: 50%;
          // height: 4.9rem;
          position: relative;
          overflow: hidden;
          box-sizing: border-box;
          ul {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            transition: all 0.5s;
            display: flex;
            li {
              width: 100%;
              height: 100%;
              list-style: none;
              float: left;
              position: relative;
              flex-shrink: 0;
              img {
                width: 100%;
                height: 100%;
                background-color: #c1c1c1;
              }
              .li-info {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                box-sizing: border-box;
                padding: 0 0.2rem;
                background-color: rgba(0, 0, 0, 0.4);
                .li-info-left,
                .li-info-right {
                  font-size: 0.18rem;
                  color: #fff;
                  // border: 1px solid #fff;
                  // padding: .05rem .15rem;
                  height: 40px;
                  width: 40px;
                  cursor: pointer;
                }
                .li-info-left {
                  background: url("../../../image/home/left.png") center center
                    no-repeat;
                  background-size: 100%;
                }
                .li-info-right {
                  background: url("../../../image/home/right.png") center center
                    no-repeat;
                  background-size: 100%;
                }
                .li-info-content {
                  margin: 0 5%;
                  color: #fff;
                  flex: 1;
                  // margin-left: -5%;
                  .title {
                    font-size: 0.24rem;
                  }
                  .detail {
                    font-size: 0.16rem;
                    line-height: 2;
                    margin: 0.2rem 0;
                    cursor: pointer;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                  .more {
                    padding: 0.05rem 0.2rem;
                    margin-top: 0.4rem;
                    width: max-content;
                    background-color: #fff;
                    color: #333;
                    border: 1px solid #fff;
                    transition: 0.5s all;
                    cursor: pointer;
                  }
                  .more:hover {
                    transition: 0.5s all;
                    color: #fff;
                    background-color: transparent;
                  }
                }
              }
            }
          }
          .li-span {
            position: absolute;
            left: 0;
            bottom: 0.2rem;
            display: flex;
            justify-content: center;
            width: 100%;
            span:nth-last-child(1) {
              margin-right: 0;
            }
            span {
              display: block;
              width: 0.1rem;
              height: 0.1rem;
              border: 1px solid #fff;
              border-radius: 50%;
              margin-right: 0.1rem;
              cursor: pointer;
            }
            .active {
              background-color: #fff;
            }
          }
        }
        .activity-right {
          width: 50%;
          margin-left: 0.4rem;
          .title {
            display: flex;
            justify-content: space-between;
            // align-content: center;
            align-items: center;
            h2 {
              margin: 0;
            }
            .detail {
              width: 1.4rem;
              height: 0.4rem;
              font-size: 0.16rem;
              line-height: 0.4rem;
              text-align: center;
              // width: max-content;
              color: #333;
              border: 1px solid #333;
              cursor: pointer;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              transition: 0.3s all;
              .details-bg {
                background: url("../../../image/right.png") center center
                  no-repeat;
                background-size: 100% 100%;
                height: 25px;
                width: 30px;
              }
              // img {
              //     height: 25px;
              // }
            }
            .detail:hover {
              background-color: #333;
              color: #fff;
              .details-bg {
                background: url("../../../image/right3.png") center center
                  no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          .right-list {
            .list-item:last-child {
              margin-bottom: 0;
            }
            .list-item {
              display: flex;
              margin: 0.38rem 0;
              cursor: pointer;
              .item-left {
                height: 0.45rem;
                width: 0.45rem;
                background-color: #ee6a22;
                border-radius: 50%;
                font-size: 0.14rem;
                flex-shrink: 0;
                line-height: 0.45rem;
                text-align: center;
                color: #fff;
                margin-right: 0.24rem;
                span {
                  font-size: 0.12rem;
                }
              }
              .item-before {
                position: relative;
              }
              .item-before::before {
                content: "";
                position: absolute;
                height: 0.4rem;
                width: 0.01rem;
                background-color: #ee6a23;
                left: 50%;
                bottom: -0.5rem;
                opacity: 0.15;
              }
              .item-right {
                .right-title {
                  display: flex;
                  align-items: center;
                }
                h3 {
                  margin: 0;
                }
                .status {
                  font-size: 0.12rem;
                  color: #ee6a23;
                  background-color: #efefef;
                  margin-left: 0.05rem;
                  border-radius: 0.02rem;
                  padding: 0 0.05rem;
                }
                .item-right-content {
                  font-size: 0.12rem;
                  margin-top: 0.1rem;
                  color: #666;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  height: 34px;
                }
              }
            }
          }
        }
      }
      .news-bg {
        width: 100%;
        background-color: #ebebeb;
      }
      .main-news {
        box-sizing: border-box;
        padding: 0.8rem 0rem 1rem 0rem;
        height: 7.05rem;
        margin: 0 auto;
        width: 1200px;
        .news-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.6rem;
          h2 {
            margin: 0;
          }
          .detail {
            // padding: .05rem .2rem;
            border: 1px solid #333;
            width: 1.4rem;
            height: 0.4rem;
            // width: max-content;
            font-size: 0.16rem;
            color: #4c4c4c;
            cursor: pointer;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            transition: 0.5s all;
            .details-bg {
              background: url("../../../image/right.png") center center
                no-repeat;
              background-size: 100%;
              height: 25px;
              width: 30px;
            }
            img {
              height: 25px;
              margin-left: 20px;
            }
          }
          .detail:hover {
            background-color: #333;
            color: #fff;
            .details-bg {
              background: url("../../../image/right3.png") center center
                no-repeat;
              background-size: 100%;
            }
          }
        }
        .news-content {
          display: flex;
          flex-wrap: wrap;
          .news-content-item:nth-child(3n) {
            margin-right: 0;
          }
          .news-content-item {
            width: calc(100% / 3 - 0.4rem/3);
            margin-right: 0.2rem;
            cursor: pointer;
            .item-img {
              width: 100%;
              height: 2.4rem;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                transition: 0.5s all;
              }
              img:hover {
                transform: scale(1.2);
                transition: 0.5s all;
              }
            }
            .item-info {
              background-color: #fff;
              box-sizing: border-box;
              padding: 0.2rem;
              h3 {
                margin: 0;
                padding-bottom: 0.1rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .info-content {
                font-size: 0.12rem;
                line-height: 2;
                margin-bottom: 0.2rem;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #666;
              }
              .detail {
                padding: 0.05rem 0.2rem;
                border: 1px solid #4c4c4c;
                width: max-content;
                font-size: 0.16rem;
                color: #333;
                transition: 0.5s all;
                cursor: pointer;
              }
              .detail:hover {
                background-color: #4c4c4c;
                transition: 0.5s all;
                color: #fff;
              }
            }
          }
        }
      }
      .main-brand {
        box-sizing: border-box;
        padding: 0.8rem 0rem 1rem 0rem;
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        h2 {
          width: 100%;
          text-align: center;
          margin: 0;
          margin-bottom: 0.8rem;
        }
        .main-brand-content {
          display: flex;
          position: relative;
          // flex-wrap: wrap;
          // margin-bottom: 1rem;
          // .brand-content-item:nth-child(5n) {
          //     margin-right: 0;
          // }
          .brand-content-item {
            padding-right: 0.4rem;
            width: calc(100% / 5 - 1.6rem / 5 + 0.4rem);
            flex-shrink: 0;
            .brand-content-item-bg {
              overflow: hidden;
              height: 90%;
              width: 100%;
              border: 1px solid #333;
              img {
                height: 100%;
                width: 100%;
                vertical-align: middle;
                transition: 0.5s all;
              }
              img:hover {
                transform: scale(1.2);
                transition: 0.5s all;
              }
            }
            .brand-content-item-title {
              // height: 25%;
              margin: 20px 0;
              width: 100%;
              font-size: 16px;
              color: #333;
              text-align: center;
              font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
    .back-top {
      position: fixed;
      right: 5%;
      bottom: 10%;
      cursor: pointer;
    }
  }
}
/deep/.our-content-info {
  p {
    span {
      background-color: transparent !important;
    }
  }
}
video::-webkit-media-controls {
  display: none !important;
}
</style>